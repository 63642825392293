<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <div
      class="max-w-wrapper-md m-auto"
      :class="{
        'py-10 lg:py-20': !isCarousel,
        'pt-[2.8125rem] pb-10 md:py-16': isCarousel
      }"
    >
      <p
        v-if="$slots['header']"
        class="lg:text-center px-4 xl:px-0"
        :class="{
          'text-2.5xl md:text-4.5xl mb-4 lg:mb-6': !isCarousel,
          'text-2xl md:text-3.5xl mb-6 md:mb-10': isCarousel
        }"
      >
        <slot name="header"></slot>
      </p>

      <p
        v-if="$slots['sub-header']"
        class="text-lg lg:text-center mb-6 lg:mb-10 max-w-wrapper-md mx-auto px-4 xl:px-0"
      >
        <slot name="sub-header"></slot>
      </p>

      <client-only>
        <component
          :is="component.wrapper.main"
          ref="swiper"
          :class="component.wrapper?.style"
          slides-per-view="auto"
          init="false"
          :style="{
            '--swiper-pagination-bottom': '0'
          }"
        >
          <component
            :is="component.wrapper.card"
            v-for="(card, cardIndex) in items"
            :key="cardIndex"
            :class="component.card"
          >
            <div
              class="w-full"
              :class="[
                component.title,
                card.titleClass,
                {
                  'bg-blue-500 text-white': cardIndex === 0 && isCarousel,
                  'bg-blue-10': cardIndex === 1 && isCarousel
                }
              ]"
              v-html="card.title"
            >
            </div>

            <ul
              class="flex flex-col"
              :class="{
                'gap-4 md:gap-6 px-4 md:px-6 py-6': card?.listIcon?.component,
                'list-disc p-4 pl-[2.375rem] md:pl-11 flex flex-col gap-3 md:gap-4': !card?.listIcon?.component
              }"
            >
              <li
                v-for="(item, itemKey) in card.list"
                :key="itemKey"
                class=""
                :class="{
                  'pl-1 md:pl-2': isCarousel,
                  'flex gap-4': card?.listIcon?.component
                }"
              >
                <component
                  :is="renderComponent(card.listIcon.component, componentMap)"
                  v-if="card?.listIcon?.component"
                  v-bind="card?.listIcon?.props"
                  class="exact-wh-[1.5rem]"
                />

                <p v-html="item"></p>
              </li>
            </ul>
          </component>
        </component>
      </client-only>

      <p
        v-if="$slots['footer']"
        class="text-sm mt-4 max-w-wrapper-md px-4 xl:px-0"
      >
        <slot name="footer"></slot>
      </p>
    </div>
  </div>
</template>

<script setup>
defineOptions({
  name: 'MoleculesHomeComparisonBlocks'
})

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  isCarousel: {
    type: Boolean,
    default: false
  }
})

const componentMap = {
  IconsCheckUnderline: resolveComponent('IconsCheckUnderline')
}

const component = computed(() => {
  const wrapper = {
    main: props.isCarousel
      ? 'swiper-container'
      : 'div',
    card: props.isCarousel
      ? 'swiper-slide'
      : 'div',
    style: getKey(props.isCarousel, {
      false: 'grid md:grid-cols-2 gap-6 md:gap-0 md:rounded-lg md:overflow-hidden md:shadow-sm-3 px-4 xl:px-0',
      true: ''
    })
  }

  const card = getKey(props.isCarousel, {
    false: 'bg-white border-r border-blue-10 last:border-0 shadow-sm-3 md:shadow-none rounded-lg md:rounded-none overflow-hidden md:overflow-auto',
    true: 'flex-col justify-start w-[336px] md:exact-w-[448px] border border-gray-400 rounded-2xl overflow-hidden text-left h-inherit'
  })

  const title = getKey(props.isCarousel, {
    false: 'py-[1.125rem] px-4 md:px-6 text-xl',
    true: 'h-12 md:h-14 flex items-center text-lg/[1.625rem] md:text-xl font-bold px-[1.10875rem] py-[0.6875rem] md:px-6 md:py-[0.875rem]'
  })

  return {
    wrapper,
    card,
    title
  }
})

const swiper = ref()

onMounted(async () => {
  await nextTick()

  const el = swiper.value

  if (el && props.isCarousel) {
    const params = {
      injectStyles: [`
        :host .swiper-pagination {
          height: 8px;
        }

        :host .swiper-pagination-bullet-active {
          background-color: #475D80 !important;
        }

        @media only screen and (max-width: 1023px) {
          :host .swiper-pagination-bullet {
            position: relative;
            top: -9px;
          }
          
          :host .swiper {
            padding: 0 16px 32px;
            width: calc(100% - 32px);
          }
        }
      `],
      pagination: {
        clickable: true
      },
      breakpoints: {
        0: {
          spaceBetween: 16
        },
        768: {
          spaceBetween: 32
        }
      }
    }

    Object.assign(el, params)
    el.initialize()
  }
})
</script>
